<!-- unit 描述 -->
<style lang="less" scoped>
    .question_des{
        background: #fff;
        border: none;
        border-radius: 5px;
        .Pr_title{
            font-size: 30px;
            letter-spacing: 0px;
            color: #cccccc;
            text-align: center;
            margin-bottom: 30px;
        }
        .Pr_img{
            display: block;
            margin: 0 auto;
            height: 2.4rem;
        }
    }
</style>
<template>
    <div class="T_box">
        <Head :Target='Target' :RightLeft='RightLeft' @show='pshow=true' :UpLayerData='UpLayerData' />
        <!-- 题干音频 -->
        <!-- <video :id="`myElement`" class="video-js vjs-default-skin vjs-big-play-centered flex-grid">
        </video> -->
        <!-- 问题音频 -->
        <!-- <video :id="`Question`" class="video-js vjs-default-skin vjs-big-play-centered flex-grid">
        </video> -->
        <!-- 作答提示音 -->
        <!-- <video :id="`Hint`" class="video-js vjs-default-skin vjs-big-play-centered flex-grid">
        </video> -->

        <AudioPlayer ref="HintAudioPlayer"  :theUrl="HintAudioPlayerUrl" @audioEndedOver="HintPlayerComplete" :config="HintAudioPlayerConfig" />
        <div class="T_content">
            <div class="Unit_d">
                <div class="Unit_con">
                    <!-- <div class="ShuiYinRight">
                        中国民用航空西北地区空中交通管理局
                    </div> -->
                    <!-- <div class="ShuiYinLeft">{{Username}}</div> -->
                    <!-- 顶部音频播放模块 -->
                    <div class="Second_title">
                        <div class="Audio_play">
                            <span class="t1" style="font-weight:bold;font-style:italic;">NO. {{questionIndex + 1}}/{{questionAll}}</span>
                            <AudioPlayer ref="stemAudioPlayer"   @audioEndedOver="PlayerComplete" @changeAudioState="changeListeningTextStatus" :theUrl="AudioPlayerUrl" :config="AudioPlayerConfig" />

                            <div class="describe" @click="pshow = !pshow"  v-if="Pattern!='测试模式'">
                                <img :src=" require( '@/assets/shuoming.png')" alt="">
                                <span>Directions</span>
                            </div>
                        
                        </div>
                    </div>
                        <div class="Second_title_describe">
                            <div class="Question_list"  v-show="Pattern!='测试模式'">
                                <span class="t1">Question. {{childQuestionListIndex + 1}}/{{childQuestionListAll}}</span>
                                <AudioPlayer ref="QuestionAudioPlayer" :theUrl="QuestionAudioPlayerUrl" @changeAudioState="changeListeningTextStatus"  @audioEndedOver="QuesetionComplete"   :config="QuestionAudioPlayerConfig" />
                            </div>
                        </div>
                    <div class="Question_box clearfix">
                        <div :class="['question_des',(threeActive=='')?'active':'']">
                            <div class="question_des flex" style="height:100%">
                                <h1 class="Pr_title" >{{ status == 'PlayRecording' ? 'Play recording': (status == 'ListeningText' ? 'Listening text': 'Start recording')}} ... </h1>
                                    <img :src=" require('../../assets/' + status +'.png') " v-if="!InPlay" class="Pr_img" alt="">
                            </div>
                        </div> 

                        <div class="Text Simu" v-show="threeActive=='TEXT'">
                            <div class="title">Text <i class="el-icon-close right" @click="threeShowClose"></i></div>
                            <div style="height:100%;">
                                <div class="t_content" v-html="this.questionText" style="border:none">
                                </div>
                            </div>
                        </div>

                        <div class="Notes" v-show="threeActive=='NOTES'">
                            <div class="title"> Notes  <i class="el-icon-close right" @click="threeShowClose"></i> </div>
                            <div class="t_content">
                                <textarea name="" v-model="NoteItem.content" @focus="NoteFocus()" @blur="NoteBlur()" maxlength="500" id="" cols="30" rows="10"></textarea>
                                <img v-if="NoteItem.content.length<=0" src="../../assets/notesbc.png" class="bc" alt="">
                                <button class="save" @click="SaveNotes"> Save </button>
                            </div>
                        </div>
                        <div class="Text" v-show="threeActive=='KEY'">
                            <div class="title">Key <i class="el-icon-close right" @click="threeShowClose"></i></div>
                            <div class="t_content" v-html="this.AnswerKey"></div>
                        </div>
                    </div> 

                </div>
            </div>
            <div class="T_option">
                <div class="Unit_option">
                    <div class="btn_list">
                        <!-- back -->
                        <span class="btn" v-if="Pattern!='测试模式'" @click="On_back()"></span>
                        <!-- next -->
                        <span class="btn2" v-if="Pattern!='测试模式'" @click="On_next()"></span>
                            <div class="Record_btn" v-if="RecordeItem.show">
                                <img src="../../assets/Microphone.png" class="T_1" alt="" @click="Record_a('1')">
                                <img src="../../assets/pause.png" class="T_2" alt="" @click='MediaPlay()' v-if="Pattern!='测试模式' && RecordeItem.MediaInPlay && recorderPlayerUrl && !RecordBtn">
                                <img src="../../assets/play_bai.png" class="T_2" alt="" @click='MediaPlay()' v-if="Pattern!='测试模式' && !RecordeItem.MediaInPlay && recorderPlayerUrl && !RecordBtn">
                                <div class="Re_conm"  v-if='RecordBtn'>
                                    <span class="horn"></span>
                                    <div class="Re_box">
                                        <div id="Wave">
                                            <div v-for="i in 10" class="WaveItem" :key="i" :style="'height:'+ Wheight(i) +'px'">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="countdown"> {{RecordeItem.timeshow}} </div>
                                </div>
                            </div>
                            <Recorder ref="Recorder" @upRcData="AddAudio"></Recorder>
                            <AudioPlayer ref="recorderPlayer"  :theUrl="recorderPlayerUrl" @audioEndedOver="recorderPlayerAudioEndedOver"  :config="recorderPlayerConfig" />
                            <!-- <span v-if="Pattern!='测试模式'&&ScoreShow&&ComplateTest=='1'&&isJudgment=='1'" style="font-size:18px;display:inline-block;vertical-align:middle;"> 等级：{{this.TiScore}} </span>
                            <span v-if="!ScoreShow&&Pattern!='测试模式'&&ComplateTest=='1'&&isJudgment=='1' " style="font-size:18px;display:inline-block;vertical-align:middle;">打分中</span> -->
                            <div class="btn_cover"  v-if='RecordBtn'></div>
                    </div>
                    <Time :Pattern='Pattern'/>
                </div>
            </div>
        </div>

        <!-- <div class="Machine" v-if="Pattern!='测试模式'">
                <img v-if="mshow.text==0" src="../../assets/TEXT3.png" alt="" style="cursor:not-allowed;">
                <img v-if="mshow.text==1" src="../../assets/1TEXT.png" alt="" @click="Clicktext">
                <img v-if="mshow.text==2" src="../../assets/2TEXT.png" alt=""  @click="mshow.text=1">
                <img v-if="mshow.notes==0" src="../../assets/NOTES3.png" alt=""  style="cursor:not-allowed;">
                <img v-if="mshow.notes==1" src="../../assets/1NOTES.png" alt=""  @click="Clicknotes">
                <img v-if="mshow.notes==2" src="../../assets/2NOTES.png" alt=""  @click="mshow.notes=1">
                <img v-if="mshow.key==0" src="../../assets/KEY3.png" alt=""  style="cursor:not-allowed;">
                <img v-if="mshow.key==1" src="../../assets/1KEY.png" alt="" @click="CheckKey()">
                <img v-if="mshow.key==2" src="../../assets/2KEY.png" alt="" @click="mshow.key=1">
        </div> -->
        <popup :show.sync="pshow" :describe='this.Target.description'></popup>
        <div class="Re_cover" v-if='RecordBtn'></div>
    </div>
</template>

<script>
    // import recording from '../../js/luyin.js'
    import popup from '../describe/Popup.vue'
    import Head from '../../components/head.vue'
    import videoJs from 'video.js'
    import "video.js/dist/video-js.min.css"
    export default {
        data(){
            return{
                // 机器人按钮显示
                mshow:{
                    text:1,
                    notes:1,
                    key:1,
                    // 0 不可选 1 可选 2 已选
                },
                // 数据加载 判断 防止二次加载
                Panduan:true,
                // 章节信息
                Target:{
                    catalog:'',
                    description:'',
                    isEnd:null,
                    title:'',
                },
                // 左右 切换按钮显示
                RightLeft:false,
                // 描述 弹窗显示
                pshow:false,

                // 问题 数据
                questionData:{},
                childQuestionData:{},
                questionText:"暂无原文",
                questionAudio:"",
                questionImg:"",


                questionIndex:0,
                questionAll:0,
                childQuestionListIndex:0, // 当前子题
                childQuestionListAll:0, // 所有子题 长度
                questionTextAndAudio:{},
                // 播放器 参数  命名 不要重复   mixin 中使用了 参数
                GetTime:false, // 获取 视频总时长
                audioTime:0, // 滑块 value
                player:null, // 播放器
                playertime:'', //总时长
                InPlay:false, // 判断是否 播放中
                // 播放器 参数

                check:'', // 判断是否选择 答案
                complate:false, // 是否提交答案
                answer:'', // 答案
                AnswerKey:'',

                NoteItem:{//笔记
                    id:'',
                    content:'',
                },
                // 绑题规则
                BindRole:{},

                // 树形 上层数据
                UpLayerData:{},

                InfoComplate:false, // 首次加载数据判断

                ProgressBar:{
                    show:false,
                    width:100,
                    time:null,
                    duration:0,
                    timeing:0,
                },
                // 问题音频
                Question:{
                    audio:null,
                    ready:false,
                    status:false,
                },
                // 录音
                RecordeItem:{
                    show:true,
                    time:null,
                    timeshow:'',
                    Count:null,
                    begin:false,
                    getAudio:null,
                    RecorderAudio: 0,  //  判断  当前 音频为录音  或  已录音音频    0 未录音   1 已录音在线音频   2本地录制音频
                    Media:null, // 录音播放 多媒体
                    MediaInPlay:false,  // 录音播放状态
                },

                wave: null,
                waveIndex: 0,

                // 提示音
                Hint: null,
                QuestionParameter:{
                    width:'500',
                    height:'500',
                    controls:true,
                    loop:false,
                    preload:'auto',
                    autoplay:false,
                },
                //是否作答
                hasRecording:false,
                AudioPlayerUrl:"",//题干音频地址
                AudioPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[1,2,3,4],
                },
                QuestionAudioPlayerUrl:"",//问题音频地址
                QuestionAudioPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[1,4],
                },
                HintAudioPlayerUrl:"",//提示音频地址
                HintAudioPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[],
                },
                recorderPlayerUrl:"",//录音音频地址
                recorderPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[],
                },
                status:"ListeningText" , // 页面状态 听录音:PlayRecording  听题:ListeningText  录音:StartRecording
            }
        },
        components:{popup,Head},
        created() {
            if(this.menulist.length>0){
                this.Panduan = true;
                this.getinfo();
            }else{
                this.Panduan = false;
            }


        },
        mounted(){
            this.RecordeItem.Media = document.createElement('audio');
            // let Question_box = document.querySelector('.Question_box').clientHeight;

            // let list = document.querySelectorAll('.Question_box>div');
            // for (let i = 0; i < list.length; i++) {
            //     const element = list[i];
            //     element.style.height = (Question_box-17) + 'px';
            // }
        },
        computed:{
            datacomplate:function(){
                return this.$store.state.common.datacomplate;
            },
            checkid:function(){
                return this.$store.state.common.checkid;
            },
            RecordBtn:function(){
                return this.$store.state.common.RecordBtn;
            },
            testPause:function(){
                return this.$store.state.common.testPause;
            },
        },
        watch:{
            datacomplate:function(v){
                if(!this.Panduan){
                    this.getinfo();
                }
            },
            dataLayer:function(){
                this.getinfo();
            },
            checkid:function(){
                if(this.InfoComplate){
                    this.getinfo();
                }
            },
            testPause:function(v,oldv){
                if(this.Pattern=='测试模式'){
                    if(v&&!oldv&&!this.complate){ // 开始
                        if(!!this.QuestionChange){
                            this.QuestionChange();
                        }else{
                            if(!!this.pauseBegin)this.pauseBegin();
                        }
                    }
                    if(oldv&&!v){ // 暂停
                        if(!!this.stayPause)this.stayPause();
                    }
                }
            },
            threeActive:function(v){
                if(v=='KEY'&&!!this.CheckKey){
                    this.CheckKey();
                }
            }
        },
        methods:{
            stayPause(){ // 测试模式暂停
                console.log(this.complate);

                if(this.complate){
                    this.$message.warning('您本题已提交答案,无法暂停!');
                    this.$store.commit('setitem',{name:'testPause',item:true});
                    return false;
                }
                this.InPlay = false;
                if(!!this.twicePlayTimeOut) clearTimeout(this.twicePlayTimeOut);
                if(!!this.player)   this.player.pause();
                if(!!this.Question.audio) this.Question.audio.pause();
                if(!!this.Hint) this.Hint.pause();
                // 录音播放停止
                if(this.RecordeItem.MediaInPlay){
                    if(this.RecordeItem.RecorderAudio == 1){
                        this.RecordeItem.Media.pause();
                    }
                    this.RecordeItem.MediaInPlay = false;
                }
                if(this.RecordBtn){
                    clearInterval(this.wave);
                    clearInterval(this.RecordeItem.Count);
                    Recorder.stop();
                    this.$store.commit('setitem',{'name':'RecordBtn','item':false})
                }
            },
            Wheight(i){
                let t = i - 1;
                let c = Math.abs(Math.abs((Math.floor(t/5)*5 - t%5)) * 40 - 300) - 30;
                let d = 300*300 - c*c;
                d = Math.sqrt(d)*2;
                return d;
            },
            async getinfo(){
                this.RecordeItem.Media = document.createElement('audio');
                let data = this.GetTargetItem();
                this.UpLayerData = data.updata; // 上 层数据
                if(!!data.last){
                    this.Target = data.data[data.last];
                }else{
                    this.Target = data.data;
                }
                this.Target.title = data.title;
                this.BindRole = this.Target.bindRole;
                // 判断 学习模式
                this.$store.commit('setitem',{'name':'Pattern','item':this.Target.bindRole.ruleTypeName})
                this.$store.commit('setitem',{'name':'RoleIsJudgment','item': this.Target.bindRole.isJudgment});
                // 判断 绑题规则 设置 notes key txt
                this.threeShow(this.BindRole);
                if(this.BindRole.isRecordNote == 1) this.mshow.notes = 1;
                else this.mshow.notes = 0;

                if(this.BindRole.isReadAnswer == 1) this.mshow.key = 1;
                else this.mshow.key = 0;

                if(this.BindRole.isReadOriginal  == 1) this.mshow.text = 1;
                else this.mshow.text = 0;
                // 判断 绑题规则 设置 notes key txt
                if(this.Target.isEnd == 1 && data.data.length>1){
                    this.RightLeft = true;
                }else{
                    this.RightLeft = false;
                }
                let item = this.Target.questionList[this.questionIndex];
                let qIndex = sessionStorage.getItem('questionIndex') || 0; // 问题下标
                this.questionIndex = sessionStorage.getItem('SonquestionIndex') || 0; // 问题子题下标
                this.questionAll = this.Target.questionList.length; // 问题 总数
                let mainId = '';
                if(this.Pattern == '测试模式' && !!this.Target.childList && this.Target.childList.length>0){
                    mainId = item.mainId;
                }else{
                    mainId = this.Target.sectionMain.id;
                }
                let questiondata = await this.GetQuestion(this.Target.questionList[qIndex].oldId,mainId);

                this.questionData = questiondata.data;  // 当前问题 数据
                if(this.Pattern == '测试模式'){
                    let dan = this.questionData.childQuestionList[item.num];
                    this.questionData.childQuestionList = [dan];
                }
                this.childQuestionListAll = this.questionData.childQuestionList.length;// 当前问题 子题总数

                this.childQuestionData = this.questionData.childQuestionList[this.childQuestionListIndex];
                this.questionTextAndAudio = JSON.parse(this.questionData.childQuestionList[this.childQuestionListIndex].title);
                let reg = /<[^>]*>|<\/[^>]*>/gm;
                if (this.questionData.questionText != null && this.questionData.questionText != undefined) {
                    this.questionData.questionText = this.questionData.questionText.replace(reg,'');
                }
                if (this.questionTextAndAudio.questionText != null && this.questionTextAndAudio.questionText != undefined) {
                    this.questionTextAndAudio.questionText = this.questionTextAndAudio.questionText.replace(reg,'');
                }
                
                if( this.questionData.questionText && this.questionTextAndAudio.questionText){
                    this.questionText = `<span >Subject text</span><div>${this.questionData.questionText}</div><span>Question text</span><div>${this.questionTextAndAudio.questionText}</div>`
                }else if(this.questionData.questionText){
                    this.questionText = `<span >Subject text</span><div>${this.questionData.questionText}</div>`

                }else if(this.questionTextAndAudio.questionText){
                    this.questionText = `<span>Question text</span><div>${this.questionTextAndAudio.questionText}</div>`
                }else{
                    this.questionText = ""
                }

                this.questionAudio = this.questionTextAndAudio.questionAudio;

                // 录音查询
                this.$refs.recorderPlayer.pausePlay()
                this.RecordeItem.MediaInPlay = false;
                let record_ = await this.AjaxApi.getNewVideo(this.childQuestionData.id);
                if(!!record_.data&&!!record_.data.filePath){
                    this.recorderPlayerUrl = record_.data.filePath
                    this.RecordeItem.RecorderAudio = '1';
                }else{
                    this.recorderPlayerUrl = null
                }
                let notes_ = await this.AjaxApi.getNoteByQuestionId(this.childQuestionData.id);
                if(!!notes_.data&&notes_.data.length>0){
                    this.NoteItem = notes_.data[0];
                }else{
                    this.NoteItem.id = '';
                    this.NoteItem.content = '';
                }
                this.$store.commit('setitem',{'name':'RecordBtn','item':false})

                console.log(this.BindRole);
                // 赋值音频路径
                this.AudioPlayerUrl = this.questionData.textResource
                this.QuestionAudioPlayerUrl = this.questionAudio
                if(this.BindRole.isAutoPaly == 1){
                    this.AudioPlayerConfig.autoplay = true
                    this.status = 'ListeningText'
                }else{
                    this.AudioPlayerConfig.autoplay = false
                }
                // let src = this.FlashUrl(this.questionData.textResource);
                // this.FlashPlay(src); // 题干音频 设置
                // this.QuestionParameter.sources = [{ type:'rtmp/mp4',src:this.FlashUrl(this.questionAudio) }];
                // this.QuestionReady();//设置小题问题音频
                this.InfoComplate = true;
            },
            // 问题音频 描述设置
            QuestionReady(){
                if(!!this.Question.audio){
                    this.Question.audio.src(this.QuestionParameter.sources);
                    if(this.QuestionParameter.autoplay){
                        this.Question.audio.play();
                    }
                    return false;
                }
                let item = JSON.parse(JSON.stringify(this.QuestionParameter));
                delete item.autoplay;
                this.Question.audio = videoJs('Question',item);
                this.Question.audio.on('ready',()=>{
                    this.Question.ready = true;
                    if(this.QuestionParameter.autoplay){
                        this.Question.audio.play();
                    }
                })
                this.Question.audio.on('canplaythrough',()=>{
                    if(this.QuestionParameter.autoplay){
                        this.Question.audio.play();
                    }
                })
                this.Question.audio.on('play',()=>{
                    sessionStorage.setItem('QuestionError',0)
                    this.Question.ready = true;
                    this.Question.status = true;
                })
                this.Question.audio.on('error',()=>{
                    console.log('音频加载失败!');
                    let error = parseInt(sessionStorage.getItem('QuestionError'));
                    error++;
                    if(error>3){
                        sessionStorage.setItem('QuestionError',0);
                        if(this.Pattern == '测试模式'){
                            this.$message.error('音频加载时失败,进入下一题!');
                            this.T_next(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
                        }
                    }else{
                        this.$message.error('音频加载时失败,重新加载播放器!');
                        sessionStorage.setItem('QuestionError',error);
                        this.QuestionReady();
                    }
                })
                this.Question.audio.on('ended',()=>{
                    this.QuesetionComplete();
                    this.Question.status = false;
                })
            },
            // 播放时间改变
            TimeChange(){
                let time = (this.audioTime / 100) *　this.player.duration();
                this.player.volume(1);
                this.player.currentTime(time);
                this.player.play();
                this.InPlay = true;
            },
            async CheckKey(){
                let url_ = await this.AjaxApi.getAnswerById(this.questionData.childQuestionList[this.childQuestionListIndex].id);
                let AnswerData = JSON.parse(url_.data.answers);
                let answerKey = '';
                if(!!AnswerData.answerText&&AnswerData.answerText!=null) answerKey = `<span>Answer</span><div>${AnswerData.answerText}</div> `;
                if(!!url_.data.answerKey&&url_.data.answerKey!=null) answerKey += `<span>Keyword</span><div>${url_.data.answerKey}</div>`;
                this.AnswerKey = answerKey;
                this.mshow.key=2;
                if(this.mshow.notes!=0) this.mshow.notes=1;
                if(this.mshow.text!=0) this.mshow.text=1;
            },
            // 保存 笔记
            async SaveNotes(){
                let question = this.questionData.childQuestionList[this.childQuestionListIndex];
                let item = this.NoteItem;
                if (item.content != null && item.content != undefined) {
                    item.content = item.content.replace(/(^\s*)|(\s*$)/g, '');
                }
                if(!!!item.content){
                    this.$message.warning('请填写笔记!');
                    return false;
                }

                let layer = sessionStorage.getItem('targetceng').split(',');
                layer = layer.map(el=>{
                    el = el.split('.');
                    return el;
                })
                let data = {
                    courseId:this.menulist[layer[0][1]].courceId,
                    nodeId:this.Target.id,
                    title:'',
                    content:item.content,
                    questionId:question.id,
                    levelName:this.Target.catalog,
                }

                if(!!item.id){
                    let edit_ = await this.AjaxApi.updateCourseNote(item);
                    if(edit_.code == '200'){
                        this.$message.success('修改笔记成功!');
                    }
                }else{
                    let add_ = await this.AjaxApi.addCourseAdvice(data);
                    if(add_.code == '200'){
                        this.$message.success('添加笔记成功!');
                        this.NoteItem.id = add_.data.id;
                    }
                }
            },
            // 录音  控制
            Record_a(it) {
                console.log('开始录音');
                if (it == '1') {
                    if (this.Pattern == '测试模式') {
                        return;
                    }
                }
                this.RecordeItem.MediaInPlay = false
                this.$store.commit('setAdd','MenuSwitch');
                // 先暂停所有音频播放
                let audios = document.getElementsByTagName('audio');
                [...audios].forEach((item) => {
                    item.pause()
                })
                // 检测是否有麦克风
                let checkInfo = JSON.parse( sessionStorage.checkInfo ) 
                if( !!!checkInfo.audioInput){
                    this.$message.warning("无音频输入设备!");
                    return
                }
                this.waveIndex = 0;
                this.recordTime = (parseInt(this.BindRole.recordDownTime)||35) - this.RecordeItem.time;

                if(!!this.BindRole.recordDownTime&&this.BindRole.recordDownTime>0){
                    this.RecordeItem.time = this.BindRole.recordDownTime;
                }else{
                    this.RecordeItem.time = 35;
                }
                this.RecordeItem.timeshow = window.ComputedTime(this.RecordeItem.time);

                if(this.RecordBtn){
                    clearInterval(this.wave);
                    clearInterval(this.RecordeItem.Count);
                    let list = document.querySelectorAll('#Wave div');
                    for (let index = 0; index < list.length; index++) {
                        let el = list[index];
                        el.className = '';
                    }
                    this.RecordeItem.RecorderAudio = 2;
                    Recorder.stop();
                    this.UpdateAudio();
                    this.$store.commit('setitem',{'name':'RecordBtn','item':false})
                }else{
                    this.$store.commit('setitem',{'name':'RecordBtn','item':true})
                    this.wave = setInterval(()=>{
                        let list = document.querySelectorAll('#Wave div');
                        if(list[this.waveIndex ].className.indexOf('active')>-1){
                            list[this.waveIndex ].className = '';
                        }else{
                            list[this.waveIndex ].className = 'active';
                        }
                        if(this.waveIndex<9){
                            this.waveIndex++;
                        }else{
                            this.waveIndex = 0;
                        }
                    },35)
                    this.RecordeItem.Count = setInterval(()=>{
                        if(this.RecordeItem.time>0){
                            this.RecordeItem.time --;
                            this.RecordeItem.timeshow = window.ComputedTime(this.RecordeItem.time);
                        }else{
                            this.recordTime = (parseInt(this.BindRole.recordDownTime)||35) - this.RecordeItem.time;
                            this.RecordeItem.RecorderAudio = 2;
                            Recorder.stop();
                            this.UpdateAudio();
                            this.$store.commit('setitem',{'name':'RecordBtn','item':false})
                            clearInterval(this.wave);
                            clearInterval(this.RecordeItem.Count);
                        }
                    },1000)
                    this.$refs.Recorder.handleBtnClick()
                    this.status = 'StartRecording'
                }
            },
            async UpdateAudio(){
                this.ComplateTest = '1';
                await this.$refs.Recorder.stopVoice()
                // Recorder.upload({
                //     url: this.$store.state.common.UploadAudio,
                //     audioParam: "file",
                //     success: (msg)=>{
                //         this.complate = true;
                //         this.AddAudio(msg);
                //     }
                // });
            },
            async AddAudio(url_){
                this.recorderPlayerUrl = url_.data;
                let path = url_.data;
                // 新增录音记录
                let data = {
                    courseId:this.$store.state.common.curriculum,
                    filePath:path,
                    questionId:this.childQuestionData.id,
                    sectionId:this.Target.id,
                    recordTime:this.recordTime,
                    seatMealCourseId:sessionStorage.getItem('seatMealCourseId')||'abc',
                    uuid:sessionStorage.getItem('uuid'),
                }
                let key = await this.AjaxApi.getAnswerById(this.questionData.childQuestionList[this.childQuestionListIndex].id);
                let AnswerData = JSON.parse(key.data.answers);
                let add_ = await this.AjaxApi.addVideo(data);
                this.status = 'PlayRecording'
                let fo = {
                    url:path,
                    type:'6',
                    bigTitleId:this.questionData.id,
                    smallTitleId:this.childQuestionData.id,
                    standardAnswer:AnswerData.answerText,
                }
                await this.GetScore(fo);
                if(this.Pattern == '测试模式'){
                    this.T_next(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
                }
            },

            //  滑块 tooltip
            formatTooltip(val){
                if(!!this.player){
                    let c = parseFloat((val / 100) * this.player.duration()).toFixed(2);
                    if(c!=NaN&&c!='NaN') c += 's'
                    else c = 's'
                    return c;
                }else{
                    return '';
                }
            },
            // 录音播放完成
            recorderPlayerAudioEndedOver(){
                this.RecordeItem.MediaInPlay = !this.RecordeItem.MediaInPlay
            },
            MediaPlay(){
                if (this.RecordBtn) {
                    clearInterval(this.wave);
                    clearInterval(this.RecordeItem.Count);
                    this.RecordeItem.RecorderAudio = 2;
                    Recorder.stop();
                    this.UpdateAudio();
                    this.$store.commit('setitem', {
                        'name': 'RecordBtn',
                        'item': false
                    })
                }
                this.$refs.recorderPlayer.startPlayOrPause()
                // if(this.RecordeItem.RecorderAudio == 0){ // 未录音
                //     this.$message.warning('请先录制音频!');
                //     return false;
                // }
                // if(this.InPlay){
                //     this.InPlay = false;
                //     this.player.pause();
                // }
                // if(this.RecordeItem.MediaInPlay){
                //     if(this.RecordeItem.RecorderAudio == 1){
                //         this.RecordeItem.Media.pause();
                //     }
                //     if(this.RecordeItem.RecorderAudio == 2){
                //         Recorder.stop();
                //     }
                // }else{
                //     let c = window.PlayerState(this.Question.audio.paused());
                //     if(!c){
                //         this.Question.status = false;
                //         this.Question.audio.pause();
                //     }
                //     if(this.RecordeItem.RecorderAudio == 1){
                //         this.RecordeItem.Media.play();
                //     }
                //     if(this.RecordeItem.RecorderAudio == 2){
                //         Recorder.play({  // 播放 监听
                //             progress: function (milliseconds) {},
                //             finished:this.PlayChange,
                //         });
                //     }
                // }
                this.RecordeItem.MediaInPlay = !this.RecordeItem.MediaInPlay;
            },
            // 重新 播放
            // ResetTime(){
            //     if(this.RecordeItem.RecorderAudio == 1){
            //         this.RecordeItem.Media.pause();
            //     }
            //     if(this.RecordeItem.RecorderAudio == 2){
            //         Recorder.stop();
            //     }
            //     this.player.volume(1);
            //     this.audioTime = 0;
            //     this.player.currentTime(0.5);
            //     this.player.play();
            //     this.InPlay = true;
            //     this.RecordeItem.MediaInPlay = false;
            //     this.twicePlayTimeOut = null;
            // },
            // 滑块  播放时段改变
            TimeChange(){
                this.RecordeItem.Media.pause();
                this.RecordeItem.MediaInPlay = false;
                let time = (this.audioTime / 100) *　this.player.duration();
                this.player.currentTime(time);
                this.player.play();
                this.InPlay = true;
            },
            // 上一题
            async prevQuestion(){
                this.questionIndex--;
                this.QuestionChange();
            },
            // 下一题
            async nextQuestion(){
                this.questionIndex++;
                this.QuestionChange();
            },
            // 数据 改变
            async QuestionChange(){
                // 数据还原
                if(!!this.ProgressBar.time) clearInterval(this.ProgressBar.time);
                this.childQuestionListIndex = 0;
                this.GetTime = false; // 获取 视频总时长
                this.audioTime = 0; // 滑块 value
                this.playertime = ''; //总时长
                this.InPlay = false; // 判断是否 播放中
                this.check = ''; // 判断是否选择 答案
                this.complate = false; // 是否提交答案
                this.answer = ''; // 答案
                this.ProgressBar = {
                    show:false,
                    width:100,
                    time:null,
                    duration:0,
                    timeing:0,
                }
                let item = this.Target.questionList[this.questionIndex];
                let mainId = '';
                if(this.Pattern == '测试模式' && !!this.Target.childList && this.Target.childList.length>0){
                    mainId = item.mainId;
                }else{
                    mainId = this.Target.sectionMain.id;
                }
                let questiondata = await this.GetQuestion(this.Target.questionList[this.questionIndex].oldId,mainId);
                this.questionData = questiondata.data;  // 当前问题 数据
                if(this.Pattern == '测试模式'){
                    let dan = this.questionData.childQuestionList[item.num];
                    this.questionData.childQuestionList = [dan];
                }
                this.childQuestionListAll = this.questionData.childQuestionList.length;// 当前问题 子题总数

                this.childQuestionData = this.questionData.childQuestionList[this.childQuestionListIndex];
                this.childQuestionData.title = JSON.parse(this.childQuestionData.title);
                this.TagReplace();
                this.questionTextAndAudio = this.childQuestionData.title;
                // this.questionText = `<span>Subject text</span><div>${this.questionData.questionText}</div><span>Question text</span><div>${this.questionTextAndAudio.questionText}</div>`;
                if( this.questionData.questionText && this.questionTextAndAudio.questionText){
                    this.questionText = `<span >Subject text</span><div>${this.questionData.questionText}</div><span>Question text</span><div>${this.questionTextAndAudio.questionText}</div>`
                }else if(this.questionData.questionText){
                    this.questionText = `<span >Subject text</span><div>${this.questionData.questionText}</div>`

                }else if(this.questionTextAndAudio.questionText){
                    this.questionText = `<span>Question text</span><div>${this.questionTextAndAudio.questionText}</div>`
                }else{
                    this.questionText = ""
                }
                this.questionAudio = this.questionTextAndAudio.questionAudio;
                // 录音查询
                this.$refs.recorderPlayer.pausePlay()
                this.RecordeItem.MediaInPlay = false;
                let record_ = await this.AjaxApi.getNewVideo(this.childQuestionData.id);
                if(!!record_.data&&!!record_.data.filePath){
                    this.recorderPlayerUrl = record_.data.filePath
                    this.RecordeItem.RecorderAudio = '1';
                }else{
                    this.recorderPlayerUrl = null
                }
                let notes_ = await this.AjaxApi.getNoteByQuestionId(this.childQuestionData.id);
                if(!!notes_.data&&notes_.data.length>0){
                    this.NoteItem = notes_.data[0];
                }else{
                    this.NoteItem.content = '';
                    this.NoteItem.id = '';
                }
                if(this.Pattern == '测试模式' && item.num != '0'){
                    // 赋值音频路径
                    this.QuestionAudioPlayerUrl = this.questionAudio
                    this.status = 'ListeningText'
                    // this.QuestionParameter.autoplay = true;
                    // this.QuestionParameter.sources = [{ type:'rtmp/mp4',src:this.FlashUrl(this.questionAudio) }];
                    // this.QuestionReady(); // 问题音频 设置
                }else{
                    // 赋值音频路径
                    this.AudioPlayerUrl = this.questionData.textResource
                    this.QuestionAudioPlayerUrl = this.questionAudio
                    this.status = 'ListeningText'
                    // let src = this.FlashUrl(this.questionData.textResource);
                    // this.FlashPlay(src); // 题干音频 设置
                    // this.QuestionParameter.autoplay = false;
                    // this.QuestionParameter.sources = [{ type:'rtmp/mp4',src:this.FlashUrl(this.questionAudio) }];
                    // this.QuestionReady();//设置小题问题音频
                }
                this.InfoComplate = true;
                this.$refs.stemAudioPlayer && this.$refs.stemAudioPlayer.RefreshChangeCurrentTime()
            },
            // 上一子题
            prevChildQuestion(){
                this.childQuestionListIndex--;
                this.ChildDataChange();
            },
            // 下一子题
            nextChildQuestion(){
                this.childQuestionListIndex++;
                this.ChildDataChange();
            },
            async ChildDataChange(){
                if(!!this.ProgressBar.time) clearInterval(this.ProgressBar.time);
                this.complate = false; // 是否提交答案
                this.answer = ''; // 答案
                this.Question.ready = false;
                this.Question.status = false;
                this.NoteItem = {//笔记
                    id:'',
                    content:'',
                };
                this.childQuestionData = this.questionData.childQuestionList[this.childQuestionListIndex];
                this.questionTextAndAudio = JSON.parse(this.questionData.childQuestionList[this.childQuestionListIndex].title)
                // this.questionText = `<span>Subject text</span><div>${this.questionData.questionText}</div><span>Question text</span><div>${this.questionTextAndAudio.questionText}</div>`;
                if( this.questionData.questionText && this.questionTextAndAudio.questionText){
                    this.questionText = `<span >Subject text</span><div>${this.questionData.questionText}</div><span>Question text</span><div>${this.questionTextAndAudio.questionText}</div>`
                }else if(this.questionData.questionText){
                    this.questionText = `<span >Subject text</span><div>${this.questionData.questionText}</div>`

                }else if(this.questionTextAndAudio.questionText){
                    this.questionText = `<span>Question text</span><div>${this.questionTextAndAudio.questionText}</div>`
                }else{
                    this.questionText = ""
                }
                this.questionAudio = this.questionTextAndAudio.questionAudio;

                //答案切换
                if(this.threeActive=='KEY'){
                    let url_ = await this.AjaxApi.getAnswerById(this.questionData.childQuestionList[this.childQuestionListIndex].id);
                    let AnswerData = JSON.parse(url_.data.answers);
                    let answerKey = '';
                    if(!!AnswerData.answerText&&AnswerData.answerText!=null) answerKey = `<span>Answer</span><div>${AnswerData.answerText}</div> `;
                    if(!!url_.data.answerKey&&url_.data.answerKey!=null) answerKey += `<span>Keyword</span><div>${url_.data.answerKey}</div>`;
                    this.AnswerKey = answerKey;
                }
                

                // 录音查询
                this.$refs.recorderPlayer.pausePlay()
                this.RecordeItem.MediaInPlay = false;
                let record_ = await this.AjaxApi.getNewVideo(this.childQuestionData.id);
                if(!!record_.data&&!!record_.data.filePath){
                    // 赋值录音路径
                    this.recorderPlayerUrl = record_.data.filePath
                    this.RecordeItem.RecorderAudio = '1';
                }else{
                    this.recorderPlayerUrl = null
                }
                let notes_ = await this.AjaxApi.getNoteByQuestionId(this.childQuestionData.id);
                if(!!notes_.data&&notes_.data.length>0){
                    this.NoteItem = notes_.data[0];
                }else{
                    this.NoteItem.content = '';
                    this.NoteItem.id = '';
                }
                // 赋值音频路径
                this.QuestionAudioPlayerUrl = this.questionAudio
                this.status = 'ListeningText'
                // let d = window.PlayerState(this.player.paused());
                // console.log(d,'autoplay');
                // this.QuestionParameter.autoplay = d;
                // this.QuestionParameter.sources = [{ type:'rtmp/mp4',src:this.FlashUrl(this.questionAudio) }];
                // if(d) this.$message.info('将播放问题音频!');
                // this.QuestionReady();//设置小题问题音频
            },
            // 音频 播放 暂停
            PlayPause(it){
                if(this.Pattern == '测试模式'){
                    return;
                }
                if(this.ClickLimit){
                    return false;
                }
                this.ClickLimit = true;
                let d = window.PlayerState(this.player.paused());
                let c = window.PlayerState(this.Question.audio.paused());
                if(d){
                    if(!c){
                        this.Question.status = false;
                        this.Question.audio.pause();
                    }
                    this.player.play();
                    this.InPlay = true;
                    if(this.RecordeItem.RecorderAudio == 1){
                        this.RecordeItem.Media.pause();
                    }
                    if(this.RecordeItem.RecorderAudio == 2){
                        Recorder.stop();
                    }
                    this.RecordeItem.MediaInPlay = false;
                }else{
                    this.player.pause();
                    this.InPlay = false;
                }
                setTimeout(()=>{
                    this.ClickLimit = false;
                },1000)
            },

            QuestionPlay(){
                if(this.Pattern == '测试模式'){
                    return;
                }
                let c = window.PlayerState(this.Question.audio.paused());
                let d = window.PlayerState(this.player.paused());
                if(c){
                    if(!d){
                        this.player.pause();
                        this.InPlay = false;
                    }
                    if(this.RecordeItem.RecorderAudio == 1){
                        this.RecordeItem.Media.pause();
                    }
                    if(this.RecordeItem.RecorderAudio == 2){
                        Recorder.stop();
                    }
                    this.Question.audio.volume(1);
                    this.Question.status = true;
                    this.Question.audio.play();
                }else{
                    this.Question.status = false;
                    this.Question.audio.pause();
                }
            },
            // 题干音频播放 完成
            PlayerComplete(){
                let c = this.$refs.QuestionAudioPlayer && this.$refs.QuestionAudioPlayer.getPlaystate();
                console.log(c,'c');
                // let c = window.PlayerState(this.Question.audio.paused());
                if(!c){
                    // this.$message('播放问题音频!');
                    setTimeout(()=>{
                        let current = parseInt(sessionStorage.getItem('PlayTwice'));
                        let {isPlayTwice,topicPlaybackInterval} = this.BindRole;
                        if(isPlayTwice==1&&current<1){
                            current++;
                            sessionStorage.setItem('PlayTwice',current);
                            let time = topicPlaybackInterval || 3;
                            time = parseInt(time) * 1000;
                            this.InPlay = true;
                            this.twicePlayTimeOut = setTimeout(this.ResetTime,time);
                        }else{
                             this.$refs.QuestionAudioPlayer && this.$refs.QuestionAudioPlayer.startPlayOrPause()
                            // this.Question.audio.play();
                            // this.Question.status = true;
                        }
                    },50)
                }
            },
            // 提示音频播放 完成
            HintPlayerComplete() {
                if(this.BindRole.haveRecordDown == 1){
                    this.Record_a();
                }
            },
            QuesetionComplete(){
                console.log('问题音频播放完成',this.BindRole);
                if(this.BindRole.isNeedReplyText == '1'){
                    this.$message(this.BindRole.replyHintText);
                }
                // 判断 是否有 提示音
                if(this.BindRole.isNeedHintAudio == 1){ // 需要 提示音
                    this.HintAudioPlayerUrl = this.BindRole.hintAudio//提示音频地址
                    this.HintAudioPlayerConfig.autoplay = true
                }else{
                    if(this.BindRole.haveRecordDown == 1){
                        this.Record_a();
                    }
                }
            },
            On_back(){
                this.ComplateTest = 0;
                this.T_back(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
            },
            On_next(){
                this.ComplateTest = 0;
                if(this.$store.state.common.MaxPattern=='闯关模式'){
                    if(!!this.hasRecording){
                        this.T_next(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
                    }else{
                        this.$message.warning('请先完成本题!');
                    }
                }else{
                    this.T_next(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
                }
            },
        },
        destroyed(){
            // 删除 播放器
            if(!!this.player){
                this.player.dispose();
            }
            if(!!this.Question.audio){
                this.Question.audio.dispose();
            }
            if(!!this.Hint){
                this.Hint.dispose();
            }
            clearInterval(this.wave);
            clearInterval(this.RecordeItem.Count);
        },
    }
</script>

<style scoped = "less">
    .Myvideo{
        margin: 0 auto;
    }
</style>
